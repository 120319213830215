import * as React from "react";

export interface StepTabProps {
  active: boolean;
  clickable: boolean;
  onClick: () => void;
}

export class StepTab extends React.Component<StepTabProps, {}> {
  constructor(props: StepTabProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  render() {
    return (
      <div className={this.className()} onClick={this.handleClick}>
        {this.props.children}
      </div>
    );
  }

  className() {
    const names = ["c-step-tab"];
    if (this.props.clickable) {
      names.push("c-step-tab--clickable");
    }
    if (this.props.active) {
      names.push("-active");
    } else {
      names.push("-inactive");
    }
    return names.join(" ");
  }

  handleClick() {
    if (this.props.clickable) {
      this.props.onClick();
    }
  }
}
