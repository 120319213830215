import * as React from "react";
import { Panel, PanelActiveContent } from "../../core/components/Panel";
import { Button } from "../../core/components/Button";

export interface TermsPanelProps {
  onClickAgree: () => void;
}

export const TermsPanel: React.SFC<TermsPanelProps> = props => (
  <Panel>
    {props.children}

    <div className="l-vertical-space-bottom">
      <PanelActiveContent>
        <Button text="I Agree" onClick={props.onClickAgree} />
      </PanelActiveContent>
    </div>
  </Panel>
);
