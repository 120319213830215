import * as React from "react";
import { OrderItem } from "./OrderItem";
import { OrderItemViewModel } from "../viewModels/OrderItemViewModel";
import { RentalOrderItem } from "gc-types";

const moment = require("moment"); // ES6 import statement doesn't work

const ItemsHeader: React.SFC<{ orderItems: OrderItemViewModel[] }> = props => {
  let headerText: JSX.Element = <span>Purchases</span>;

  const firstItem: RentalOrderItem = props.orderItems[0] as RentalOrderItem;
  const isRentalItems = firstItem.start_date ? true : false;

  if (isRentalItems) {
    const formatDate = (date: string) => moment(date).format("Do MMM");
    const start = formatDate(firstItem.start_date);
    const end = formatDate(firstItem.end_date);

    headerText = (
      <span>
        Rental period:&nbsp;
        {
          <strong>
            {start}. &ndash; {end}.
          </strong>
        }
      </span>
    );
  }

  return <h3 className="t-order-items-header c-panel__header">{headerText}</h3>;
};

const groupItemsByRentalPeriodOrSales = (orderItems: OrderItemViewModel[]) => {
  const addTo = (array: any[], item: any) => {
    if (array === undefined) {
      array = [];
    }
    array.push(item);
    return array;
  };

  return orderItems.reduce((acc, item) => {
    const key = item.is_rental ? `${item.start_date}:${item.end_date}` : "sale";

    acc[key] = addTo(acc[key], item);

    return acc;
  }, {});
};

interface OrderItemsProps {
  countText: string;
  itemsTotalInDollars: string;
  orderItems: OrderItemViewModel[];
  showItemsHeader?: boolean;
}

export const OrderItems: React.SFC<OrderItemsProps> = props => {
  const groupedItems = groupItemsByRentalPeriodOrSales(props.orderItems);

  const toOrderItem = (key: string) => {
    const orderItems = groupedItems[key].map((item: OrderItemViewModel, index: number) => (
      <OrderItem key={index} orderItem={item} />
    ));

    return (
      <div key={key}>
        {props.showItemsHeader ? <ItemsHeader orderItems={groupedItems[key]} /> : null}

        {orderItems}
      </div>
    );
  };

  return (
    <section>
      {Object.keys(groupedItems)
        .sort()
        .map(toOrderItem)}

      <hr />

      <div className="c-order-items__total">
        <div className="t-order-items__count">{props.countText}</div>

        <div className="t-order-items-total">{props.itemsTotalInDollars}</div>
      </div>
    </section>
  );
};

OrderItems.defaultProps = {
  showItemsHeader: true
};
