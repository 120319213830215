import * as React from "react";
import { StepTabs } from "./StepTabs";
import { StepTab } from "./StepTab";
import { Columns } from "./Columns";
import { ActivatableElement } from "./Step";
import { ReactNode } from "react";
import { ContentActive } from "./ContentActive";

export interface StepsProps {
  children: ReactNode[];
  activeStepIndex: number;
  onRequestChangeTab: (index: number) => void;
}

export class Steps extends React.Component<StepsProps, {}> {
  constructor(props: StepsProps) {
    super(props);
  }

  render() {
    return (
      <div>
        <StepTabs>{this.stepTabs()}</StepTabs>
        <Columns>{this.columnContents()}</Columns>
      </div>
    );
  }

  stepTabs() {
    const elements: React.ReactNode[] = [];
    this.props.children.forEach((step: any, index: number) => {
      elements.push(
        <StepTab
          active={this.props.activeStepIndex === index}
          clickable={index < this.props.activeStepIndex}
          onClick={() => this.handleStepTabClick(index)}
          key={index}
        >
          {step.props.tabName}
        </StepTab>
      );
    });
    return elements;
  }

  columnContents() {
    const elements: any = [];
    this.props.children.forEach((step: any, index: number) => {
      const columnElements: ActivatableElement[] = [];
      this.arrayOrElementToArray(step.props.children).forEach((element: any, panelIndex) => {
        columnElements.push(
          <ContentActive active={this.props.activeStepIndex === index} key={`${index}x${panelIndex}`}>
            {element}
          </ContentActive>
        );
      });
      elements.push(<div key={index}>{columnElements}</div>);
    });
    return elements;
  }

  arrayOrElementToArray(arrayOrElement: ActivatableElement[] | ActivatableElement): ActivatableElement[] {
    if (typeof arrayOrElement["length"] === "number") {
      return arrayOrElement as ActivatableElement[];
    } else {
      return [arrayOrElement as ActivatableElement];
    }
  }

  handleStepTabClick(index: number) {
    this.props.onRequestChangeTab(index);
  }
}
