import * as React from "react";

export interface StepTabsProps {
  children: React.ReactNode[]; // must be an array
}

export class StepTabs extends React.Component<StepTabsProps, {}> {
  constructor(props: StepTabsProps) {
    super(props);
    if (this.numberOfSteps() !== 3) {
      throw new Error("only implemented for 3 steps");
    }
  }

  render() {
    return (
      <div className="grid-container l-vertical-space">
        <div className="c-step-tabs">
          <div className="grid-row">
            <div className="grid-col-4">{this.props.children[0]}</div>
            <div className="grid-col-4">{this.props.children[1]}</div>
            <div className="grid-col-4">{this.props.children[2]}</div>
          </div>
        </div>
      </div>
    );
  }

  private numberOfSteps(): number {
    return this.props.children.length;
  }
}
