import * as React from "react";
import { Grid } from "./Grid";

export interface ColumnsProps {
  children?: any;
}

export class Columns extends React.Component<ColumnsProps, {}> {
  constructor(props: ColumnsProps) {
    super(props);
  }
  render() {
    const columns = this.props.children.length;
    return <Grid columns={columns}>{this.props.children}</Grid>;
  }
}
