import { blankSubOrderViewModel, subOrderViewModel, SubOrderViewModel } from "../viewModels/SubOrderViewModel";
import * as React from "react";
import InventoryAppClientGateway, { InventoryAppClientGatewayError } from "../InventoryAppClientGateway";
import { getQueryParams, Params, sendAnalytics } from "../../core/utils";
import { Either } from "fp-ts/lib/Either";
import { SubOrder } from "gc-types/generated/types";
import { OrderDetailsAnalyticsEvents } from "../constants";
import { CancelPage } from "./CancelPage";

interface CancelPageContainerState {
  subOrder: SubOrderViewModel;
}

export class CancelPageContainer extends React.Component<{}, CancelPageContainerState> {
  private readonly inventory: InventoryAppClientGateway;
  private readonly params: Params;

  constructor(props: {}) {
    super(props);

    this.inventory = new InventoryAppClientGateway();
    this.state = {
      subOrder: blankSubOrderViewModel
    };
    this.params = getQueryParams();

    this.cancelOrder = this.cancelOrder.bind(this);
  }

  componentDidMount() {
    const baseOrderId = this.params.base_order_id;

    if (baseOrderId === undefined) {
      return Promise.resolve();
    }

    const rentalDates = this.params.start_date
      ? {
          startDate: this.params.start_date,
          endDate: this.params.end_date
        }
      : undefined;

    return this.inventory
      .getSubOrderWithOrderIdAndRentalDates(baseOrderId, rentalDates)
      .then((result: Either<InventoryAppClientGatewayError, SubOrder>) => {
        result.fold<void>(
          err => console.error(err),
          subOrder => {
            this.setState({ subOrder: subOrderViewModel(subOrder) });

            sendAnalytics(OrderDetailsAnalyticsEvents.OrderDetailsLoaded, {
              subOrderId: subOrder.sub_order_id
            });
          }
        );
      });
  }

  render() {
    return <CancelPage subOrder={this.state.subOrder} onSubmit={this.cancelOrder} />;
  }

  private cancelOrder(reason: string) {
    const baseOrderId = this.params.base_order_id;
    if (baseOrderId === undefined) {
      return Promise.reject("Missing base order ID");
    }

    const subOrderId = this.state.subOrder.sub_order_id;

    return this.inventory
      .cancelSubOrder(baseOrderId, subOrderId, { reason })
      .then((result: Either<InventoryAppClientGatewayError, SubOrder>) => {
        result.fold<void>(
          err => console.error(err),
          subOrder => this.setState({ subOrder: subOrderViewModel(subOrder) })
        );
      });
  }
}
