import * as React from "react";

interface HeaderProps {
  className?: string;
  onClick?: () => void;
}

const crossSvg = process.env.NODE_ENV !== "test" ? require("../../../../assets/cross.svg") : "";

export const Header: React.SFC<HeaderProps> = props => (
  <div className="c-header">
    <h2 className={`c-heading-2 ${props.className}`}>{props.children}</h2>
    <button className="c-header__close" type="button" onClick={props.onClick}>
      <img src={crossSvg} className="c-close-cross" />
    </button>
  </div>
);
