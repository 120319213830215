import * as React from "react";

export interface GridProps {
  columns: number;
  children: React.ReactChild | React.ReactNode[];
  className?: string;
}

export class Grid extends React.Component<GridProps, {}> {
  constructor(props: GridProps) {
    super(props);
  }

  render() {
    return (
      <div className={`grid-container ${this.props.className || ""}`}>
        <div className="grid-row">{this.renderColumns()}</div>
      </div>
    );
  }

  private renderColumns() {
    const columns: React.ReactNode[] = [];
    const children = (this.props.children["forEach"]
      ? this.props.children
      : [this.props.children]) as React.ReactNode[];
    children.forEach((child, index) => {
      columns.push(
        <div className={`${this.gridItemClass()} t-col`} key={index}>
          {child}
        </div>
      );
    });
    return columns;
  }

  private gridItemClass(): string {
    const desktopSize = 12 / this.props.columns;
    return `grid-col-d-${desktopSize} grid-col-m-12`;
  }
}
