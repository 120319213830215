import { SubOrder, SubOrderStatus } from "gc-types";
import { centsToDollars, sum } from "../../../common/util";
import { orderItemViewModel, OrderItemViewModel } from "./OrderItemViewModel";

export type SubOrderViewModel = SubOrder & {
  cancellationFeeInDollars: () => string;
  countText: () => string;
  effectiveDeliveryCostInDollars: () => string;
  refundableAmountInDollars: () => string;
  isEmpty: () => boolean;
  itemsTotalInDollars: () => string;
  orderItems: OrderItemViewModel[];
  orderTotalInDollars: () => string;
};

const blankSubOrder: SubOrder = {
  sub_order_id: "",
  order_items: [],
  effective_delivery_cost_in_cents: 0,
  is_cancelled: false,
  status: SubOrderStatus.Unknown,
  shipping_address: {
    street: ["8D Hiles Street"],
    suburb: "ALEXANDRIA",
    state: "NSW",
    post_code: "2015",
    country_code: "AU"
  }
};

const defaultCancellationFeeInCents = 0;

export function subOrderViewModel(subOrderOrToken: SubOrder | { _blankForTest: true }): SubOrderViewModel {
  const subOrder: SubOrder = subOrderOrToken["_blankForTest"] ? blankSubOrder : (subOrderOrToken as SubOrder);
  const orderItems = subOrder.order_items.map(o => orderItemViewModel(o));

  function itemsTotalInCents(): number {
    return sum(orderItems.map(o => o.price_paid_in_cents));
  }

  function eligibleAmountInCents(): number {
    const deliveryCost = subOrder.status === SubOrderStatus.Reserved ? subOrder.effective_delivery_cost_in_cents : 0;

    return itemsTotalInCents() + deliveryCost - defaultCancellationFeeInCents;
  }

  return Object.assign(Object.create(subOrder), {
    orderItems: orderItems,

    countText() {
      const count = this.orderItems.length;
      const noun = count === 1 ? "item" : "items";
      return `${count} ${noun}`;
    },

    effectiveDeliveryCostInDollars() {
      return "$" + centsToDollars(subOrder.effective_delivery_cost_in_cents);
    },

    cancellationFeeInDollars() {
      return "$" + centsToDollars(defaultCancellationFeeInCents);
    },

    itemsTotalInDollars() {
      return "$" + centsToDollars(itemsTotalInCents());
    },

    refundableAmountInDollars() {
      return "$" + centsToDollars(eligibleAmountInCents());
    },

    isEmpty() {
      return orderItems.length === 0;
    },

    orderTotalInDollars() {
      return "$" + centsToDollars(itemsTotalInCents() + subOrder.effective_delivery_cost_in_cents);
    }
  });
}

export const blankSubOrderViewModel: SubOrderViewModel = subOrderViewModel(blankSubOrder);
