import * as React from "react";

function className(active: boolean) {
  if (active) {
    return "-content-active";
  } else {
    return "-content-inactive";
  }
}

export const ContentActive: React.SFC<{ active: boolean }> = props => (
  <div className={className(props.active)}>{props.children}</div>
);
