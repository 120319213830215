import * as React from "react";

interface TotalLineItem {
  label: string | JSX.Element;
  value: string | JSX.Element;
}

interface OrderTotalsProps {
  lineItems: TotalLineItem[];
}

export const OrderTotals: React.SFC<OrderTotalsProps> = props => {
  return (
    <div className="c-order-totals">
      <div className="c-order-totals__heading">Your Order</div>
      <dl className="c-order-totals__list">
        {props.lineItems.map(lineItem => (
          <React.Fragment key={`${lineItem.label}-${lineItem.value}`}>
            <dt className="t-order-totals-item-count c-order-totals__item">{lineItem.label}</dt>
            <dd className="t-order-totals-item-total c-order-totals__value">{lineItem.value}</dd>
          </React.Fragment>
        ))}
      </dl>
    </div>
  );
};
