import { OrderItem } from "gc-types";
import { centsToDollars } from "../../../common/util";

export type OrderItemViewModel = OrderItem & {
  pricePaidInDollars: () => string;
};

export function orderItemViewModel(orderItem: OrderItem): OrderItemViewModel {
  return Object.assign(Object.create(orderItem), {
    pricePaidInDollars() {
      return centsToDollars(orderItem.price_paid_in_cents);
    }
  });
}
