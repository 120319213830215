import * as React from "react";
import { SubOrderViewModel } from "../viewModels/SubOrderViewModel";
import { OrderTotals } from "../components/OrderTotals";

export const CancelOrderTotals: React.SFC<{ subOrder: SubOrderViewModel }> = props => (
  <OrderTotals
    lineItems={[
      { label: props.subOrder.countText(), value: props.subOrder.itemsTotalInDollars() },
      { label: "Delivery & return", value: props.subOrder.effectiveDeliveryCostInDollars() },
      { label: "Cancellation fee", value: props.subOrder.cancellationFeeInDollars() },
      {
        label: (
          <>
            <strong>Eligible refund</strong> (inc. GST)
          </>
        ),
        value: <strong>{props.subOrder.refundableAmountInDollars()}</strong>
      }
    ]}
  />
);
