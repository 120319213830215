import * as React from "react";
import { Grid } from "./Grid";

export interface Breadcrumb {
  label: string;
  href: string | null | JSX.Element;
}

interface BreadcrumbsProps {
  breadcrumbs: Breadcrumb[];
  className?: string;
}

export const Breadcrumb: React.SFC<Breadcrumb> = props => {
  const link = (children: React.ReactNode) => (
    <a href={props.href as string} target="_parent">
      {children}
    </a>
  );
  const span = (children: React.ReactNode) => <span>{children}</span>;
  // const wrap = props.href ? link : span;
  let wrap;

  if (props.href) {
    if (React.isValidElement(props.href)) {
      wrap = (children: React.ReactNode) => props.href;
    } else {
      wrap = link;
    }
  } else {
    wrap = span;
  }

  return <div className="c-breadscrumbs__breadcrumb">{wrap(props.label)}</div>;
};

export const Breadcrumbs: React.SFC<BreadcrumbsProps> = props => (
  <Grid columns={1}>
    <div className={`c-breadcrumbs ${props.className || ""}`.trim()}>
      {props.breadcrumbs.map((breadcrumb, index) => <Breadcrumb {...breadcrumb} key={index} />)}
    </div>
  </Grid>
);
