import * as React from "react";
import { Panel, PanelActiveContent } from "../../core/components/Panel";
import { Button } from "../../core/components/Button";
import { Select } from "../../core/components/Select";

interface Reason {
  label: string;
  value: string;
  details:
    | {
        label: string;
        value: string;
      }[]
    | null;
}

interface ReasonPanelProps {
  heading: string;
  onChange: (reason: string) => void;
  onNext: () => void;
  reasons: Reason[];
}

interface ReasonPanelState {
  visibleReasonDetail: JSX.Element | null;
}

export class ReasonPanel extends React.Component<ReasonPanelProps, ReasonPanelState> {
  private readonly reasonDetails: {};

  constructor(props: ReasonPanelProps) {
    super(props);

    this.state = {
      visibleReasonDetail: null
    };

    this.reasonDetails = this.props.reasons.reduce((acc, reasonCategory) => {
      let reasonWithDetail = (detailValue: string) => `${reasonCategory.value}|${detailValue}`;
      const onReasonDetailsSelected = (reasonDetail: string) => {
        let detailSelected = reasonDetail.length !== 0;

        const reason = detailSelected ? reasonWithDetail(reasonDetail) : "";
        this.props.onChange(reason);
      };

      acc[reasonCategory.value] = reasonCategory.details ? (
        <Select key={reasonCategory.value} onChange={onReasonDetailsSelected}>
          <option />

          {reasonCategory.details.map(detail => (
            <option key={reasonWithDetail(detail.value)} value={detail.value}>
              {detail.label}
            </option>
          ))}
        </Select>
      ) : null;

      return acc;
    }, {});

    this.onReasonCategoryChange = this.onReasonCategoryChange.bind(this);
  }

  render() {
    return (
      <Panel>
        <p className="l-vertical-space-bottom t-reason-heading">
          <strong>{this.props.heading}</strong>
        </p>

        <div className="">
          <label className="c-label l-vertical-space-bottom">
            <span className="c-label__text">REASONS</span>
            <Select onChange={this.onReasonCategoryChange}>
              <option />

              {this.props.reasons.map(reason => (
                <option key={reason.value} value={reason.value}>
                  {reason.label}
                </option>
              ))}
            </Select>
          </label>

          {this.state.visibleReasonDetail ? (
            <label className="js-reason-details c-label l-vertical-space-bottom">
              <span className="c-label__text">DETAILS</span>
              {this.state.visibleReasonDetail}
            </label>
          ) : null}
        </div>

        <div className="l-vertical-space-bottom">
          <PanelActiveContent>
            <Button text="Next" onClick={this.props.onNext} />
          </PanelActiveContent>
        </div>
      </Panel>
    );
  }

  private onReasonCategoryChange(value: string) {
    this.setState({
      visibleReasonDetail: this.reasonDetails[value]
    });

    const categoryWithNoDetails = !this.reasonDetails[value];
    if (categoryWithNoDetails) {
      this.props.onChange(value);
    } else {
      this.props.onChange("");
    }
  }
}
