import { ParentWindow } from "./ParentWindow";

const parentWindow = new ParentWindow();

export interface Params {
  [key: string]: string | undefined;
}

export const getQueryParams = (): Params => {
  return window.location.search
    .substring(1)
    .split("&")
    .reduce((acc, param) => {
      const [key, value] = param.split("=");

      acc[key] = value;

      return acc;
    }, {});
};

export function sendAnalytics(eventName: string, eventData: object) {
  parentWindow.postMessage({
    type: "broadcast",
    data: {
      eventName,
      eventData
    }
  });
}
