export function centsToDollars(cents: number): string {
  cents = Math.floor(cents);
  const wholeDollars = Math.floor(cents / 100);
  const remainingCents = cents % 100;
  const centsPart = remainingCents < 10 ? `0${remainingCents}` : remainingCents;
  return `${wholeDollars}.${centsPart}`;
}

export function sum(values: number[]) {
  return values.reduce((acc: number, value: number) => acc + value, 0);
}
