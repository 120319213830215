export interface PostMessageData {
  type: string;
  data: object;
}

export class ParentWindow {
  constructor(private readonly domParentWindow: Window = window.parent.window) {}

  goto(url: string) {
    this.domParentWindow.location.href = url;
  }

  location() {
    return this.domParentWindow.location;
  }

  postMessage(message: PostMessageData) {
    this.domParentWindow.postMessage(message, this.domParentWindow.location.origin);
  }

  scrollToTop() {
    this.domParentWindow.scroll(0, 0);
  }
}
