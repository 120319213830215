import * as React from "react";

export interface ButtonProps {
  className?: string;
  onClick: () => void;
  text: string;
}

export const Button: React.SFC<ButtonProps> = props => (
  <button className={`c-button ${props.className}`} onClick={props.onClick}>
    {props.text}
  </button>
);

Button.defaultProps = {
  className: ""
};
