import * as React from "react";

interface PanelProps {
  className?: string;
  ready?: boolean;
}

export const Panel: React.SFC<PanelProps> = props => {
  const readyClass = props.ready ? "-ready" : "-not-ready";
  return <div className={`c-panel ${props.className} ${readyClass}`}>{props.children}</div>;
};

Panel.defaultProps = {
  className: "",
  ready: true
};

export const PanelActiveContent: React.SFC = props => <div className="c-panel__active-content">{props.children}</div>;
