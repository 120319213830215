import * as React from "react";
import { Banner } from "./Banner";
import { Header } from "./Header";
import { Breadcrumb, Breadcrumbs } from "./Breadcrumbs";

interface MyAccountPageProps {
  banner: {
    heading: string;
    subHeading: JSX.Element;
    content?: JSX.Element;
  };
  breadcrumbs: Breadcrumb[];
  header: JSX.Element;
  onCloseHeader?: () => void;
}

export const MyAccountPage: React.SFC<MyAccountPageProps> = props => (
  <article className="c-cancel-my-order">
    <section>
      <Breadcrumbs className="l-m-hidden" breadcrumbs={props.breadcrumbs} />

      <Banner className="l-m-hidden" heading={props.banner.heading} subHeading={<div>{props.banner.subHeading}</div>}>
        <span>{props.banner.content}</span>
      </Banner>

      <Header className="l-d-hidden" onClick={props.onCloseHeader}>
        {props.header}
      </Header>
    </section>

    {props.children}
  </article>
);
