import { SubOrder } from "gc-types";
import { Either, Left, Right } from "fp-ts/lib/Either";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { InventoryAppClientError } from "../../server/inventoryAppClient";
import { RentalDates } from "../../common/RentalDates";

type ClientServerError = { error: "client-server"; cause: any };

const axiosApi = axios.create({
  baseURL: "/api/v1",
  timeout: 10000
});

function escapeId(subOrderId: string) {
  return subOrderId.replace("/", "~");
}

export type InventoryAppClientGatewayError = InventoryAppClientError | ClientServerError;

export default class InventoryAppClientGateway {
  private api: AxiosInstance;

  constructor(api: AxiosInstance = axiosApi) {
    this.api = api;
  }

  getSubOrderWithOrderIdAndRentalDates(
    baseOrderId: string,
    rentalDates?: RentalDates
  ): Promise<Either<InventoryAppClientGatewayError, SubOrder>> {
    let subOrderId = `order_id=${baseOrderId}${toSubOrderId(rentalDates)}`;

    return this.getSubOrder(baseOrderId, subOrderId);
  }

  getSubOrder(baseOrderId: string, subOrderId: string): Promise<Either<InventoryAppClientGatewayError, SubOrder>> {
    return this.proxy("get", `/suborder/${escapeId(subOrderId)}?base_order_id=${baseOrderId}`);
  }

  cancelSubOrder(
    baseOrderId: string,
    subOrderId: string,
    data: { reason: string }
  ): Promise<Either<InventoryAppClientGatewayError, SubOrder>> {
    return this.proxy("post", `/suborder/${escapeId(subOrderId)}/cancel?base_order_id=${baseOrderId}`, data);
  }

  refundSubOrder(
    baseOrderId: string,
    subOrderId: string,
    data: { reason: string }
  ): Promise<Either<InventoryAppClientGatewayError, SubOrder>> {
    return this.proxy("post", `/suborder/${escapeId(subOrderId)}/refund?base_order_id=${baseOrderId}`, data);
  }

  private proxy(
    httpMethod: "get" | "post",
    url: string,
    data: any = ""
  ): Promise<Either<InventoryAppClientGatewayError, SubOrder>> {
    const responsePromise = this.api.request({
      url,
      method: httpMethod,
      data: data
    });

    return responsePromise
      .then((response: AxiosResponse<any>) => hydrateResponse(response))
      .catch((err: any) => left({ error: "client-server", cause: err }));
  }
}

function hydrateResponse(response: AxiosResponse<any>) {
  if (response.data.body) {
    return right(response.data.body);
  } else {
    return left(response.data);
  }
}

function left(value: InventoryAppClientGatewayError) {
  return new Left<InventoryAppClientGatewayError, SubOrder>(value);
}

function right(value: SubOrder) {
  return new Right<InventoryAppClientGatewayError, SubOrder>(value);
}

function toSubOrderId(rentalDates?: RentalDates) {
  let queryParam = "";

  if (!rentalDates) {
    return queryParam;
  }

  queryParam = queryParam + `|start_date=${rentalDates.startDate}`;

  if (rentalDates.endDate) {
    queryParam = queryParam + `|end_date=${rentalDates.endDate}`;
  }

  return queryParam;
}
