import * as React from "react";
import { Divider } from "./Divider";
import { Columns } from "./Columns";

interface BannerProps {
  className?: string;
  heading: string;
  subHeading: string | JSX.Element;
  copy?: string;
}

export const Banner: React.SFC<BannerProps> = props => (
  <section className={`c-banner ${props.className}`}>
    <Columns>
      <div>&nbsp;</div>

      <div className="c-banner__content">
        <div className="t-banner-heading">
          <h1 className="c-heading-1 c-banner__heading">{props.heading}</h1>
          <div>{props.subHeading}</div>
        </div>

        <Divider />

        <div className="t-banner-content">{props.children}</div>
      </div>

      <div>&nbsp;</div>
    </Columns>
  </section>
);
