import * as React from "react";
import { Steps } from "../../core/components/Steps";
import { Step } from "../../core/components/Step";
import { TermsPanel } from "../components/TermsPanel";
import { ReasonPanel } from "../components/ReasonPanel";
import { CancelMyOrderPanel } from "./CancelMyOrderPanel";
import { SubOrderViewModel } from "../viewModels/SubOrderViewModel";

let reasons = [
  { label: "Event cancelled", value: "event-cancelled", details: null },
  { label: "Event postponed", value: "event-postponed", details: null },
  { label: "Booked the wrong date", value: "booked-the-wrong-date", details: null },
  { label: "No longer attending the event", value: "no-longer-attending-the-event", details: null },
  { label: "Chose another outfit from GC", value: "chose-another-outfit-from-gc", details: null },
  { label: "Dress code change", value: "dress-code-change", details: null },
  { label: "Bought an outfit", value: "bought-an-outfit", details: null },
  { label: "GC cannot fulfil my order", value: "gc-cannot-fulfil-my-order", details: null },
  { label: "Delivery Issue", value: "delivery-issue", details: null },
  { label: "Other", value: "other", details: null }
];

interface CancelStepsProps {
  onCancellationConfirmed: (reason: string) => void;
  onReasonConfirmed: (reason: string) => void;
  onTermsConfirmed: () => void;
  subOrder: SubOrderViewModel;
}

interface CancelStepsState {
  activeStepIndex: number;
  reason: string;
}

export class CancelSteps extends React.Component<CancelStepsProps, CancelStepsState> {
  constructor(props: CancelStepsProps) {
    super(props);
    this.handleClickAgree = this.handleClickAgree.bind(this);
    this.handleClickReasonNext = this.handleClickReasonNext.bind(this);
    this.handleReasonChanged = this.handleReasonChanged.bind(this);
    this.handleRequestChangeTab = this.handleRequestChangeTab.bind(this);
    this.state = { activeStepIndex: 0, reason: "" };
  }

  render() {
    return (
      <Steps
        activeStepIndex={this.props.subOrder.isEmpty() ? -1 : this.state.activeStepIndex}
        onRequestChangeTab={this.handleRequestChangeTab}
      >
        <Step tabName="1. TERMS">
          <TermsPanel onClickAgree={this.handleClickAgree}>
            <p className="l-vertical-space-bottom">In order to cancel your order, we need to confirm the following</p>

            <div className="l-vertical-space-bottom">
              <ul className="c-list">
                <li className="c-list__item--tick">
                  You cannot partially cancel your order, to request an order change please{" "}
                  <a href="https://returntoglamcorner.typeform.com/to/LOyj3l" target="_parent">
                    click here.
                  </a>
                </li>

                <li className="c-list__item--tick">
                  Once your order has been cancelled we cannot reactivate your booking. If you would like to re-order
                  please place a new booking.
                </li>

                <li className="c-list__item--tick">Cancellations are only eligible for 100% refund.</li>
              </ul>
            </div>
          </TermsPanel>
        </Step>

        <Step tabName="2. REASON">
          <ReasonPanel
            heading="Why do you want to cancel your order?"
            onChange={this.handleReasonChanged}
            onNext={this.handleClickReasonNext}
            reasons={reasons}
          />
        </Step>

        <Step
          tabName={
            <span>
              3. CANCEL<span className="l-m-hidden"> MY ORDER</span>
            </span>
          }
        >
          <CancelMyOrderPanel
            subOrder={this.props.subOrder}
            onCancellationConfirmed={() => this.props.onCancellationConfirmed(this.state.reason)}
          />
        </Step>
      </Steps>
    );
  }

  private handleClickAgree() {
    this.setState(prevState => {
      return prevState.activeStepIndex === 0 ? { activeStepIndex: 1 } : null;
    });

    this.props.onTermsConfirmed();
  }

  private handleClickReasonNext() {
    this.setState(prevState => {
      if (prevState.activeStepIndex === 1) {
        if (prevState.reason === "") {
          alert("Please select a reason");
          return null;
        } else {
          return { activeStepIndex: 2 };
        }
      } else {
        return null;
      }
    });

    this.props.onReasonConfirmed(this.state.reason);
  }

  private handleReasonChanged(value: string) {
    this.setState({
      reason: value
    });
  }

  private handleRequestChangeTab(index: number) {
    this.setState({ activeStepIndex: index });
  }
}
