import * as React from "react";
import { Panel, PanelActiveContent } from "../../core/components/Panel";
import { OrderItems } from "../components/OrderItems";
import { Button } from "../../core/components/Button";
import { SubOrderViewModel } from "../viewModels/SubOrderViewModel";
import { CancelOrderTotals } from "./CancelOrderTotals";

interface CancelMyOrderPanelProps {
  onCancellationConfirmed: () => void;
  subOrder: SubOrderViewModel;
}

interface CancelMyOrderPanelState {
  cancellationInProgress: boolean;
}

export class CancelMyOrderPanel extends React.Component<CancelMyOrderPanelProps, CancelMyOrderPanelState> {
  constructor(props: CancelMyOrderPanelProps) {
    super(props);

    this.state = {
      cancellationInProgress: false
    };

    this.onCancelClick = this.onCancelClick.bind(this);
  }

  render() {
    return (
      <Panel className="c-panel--with-header" ready={!this.props.subOrder.isEmpty()}>
        <div className="l-vertical-space-bottom">
          <OrderItems
            orderItems={this.props.subOrder.orderItems}
            itemsTotalInDollars={this.props.subOrder.itemsTotalInDollars()}
            countText={this.props.subOrder.countText()}
          />
        </div>

        <p className="l-vertical-space-bottom">
          Once cancelled your refund will be processed within 3 - 5 business days. Please allow an additional 2 business
          days for the funds to reach your account.
        </p>

        <div className="l-vertical-space-bottom">
          <CancelOrderTotals subOrder={this.props.subOrder} />
        </div>

        <div className="l-vertical-space-bottom">
          <PanelActiveContent>
            <div className="l-vertical-space-bottom">
              {this.state.cancellationInProgress ? (
                <div className="t-loading-button c-button c-button--loading" />
              ) : (
                <Button text="Cancel My Order" onClick={this.onCancelClick} />
              )}
            </div>

            <span className="c-cancel-my-order__cancel-panel-terms-and-conditions">
              By continuing, you accept our{" "}
              <a href="https://www.glamcorner.com.au/info/terms">Terms &amp; Conditions</a>
            </span>
          </PanelActiveContent>
        </div>
      </Panel>
    );
  }

  private onCancelClick() {
    this.setState({ cancellationInProgress: true });
    this.props.onCancellationConfirmed();
  }
}
