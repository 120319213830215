import * as React from "react";
import { hot } from "react-hot-loader";
import { CancelSteps } from "./CancelSteps";
import { SubOrderViewModel } from "../viewModels/SubOrderViewModel";
import { CancelledOrder } from "./CancelledOrder";
import { getQueryParams, Params, sendAnalytics } from "../../core/utils";
import { Button } from "../../core/components/Button";
import { CancelMyOrderAnalyticsEvents } from "./constants";
import { ParentWindow } from "../../core/ParentWindow";
import { MyAccountPage } from "../../core/components/MyAccountPage";
import { SubOrderStatus } from "gc-types/generated/types";

interface CancelPageProps {
  onSubmit: (reason: string) => void;
  subOrder: SubOrderViewModel;
}

export class CancelPage extends React.Component<CancelPageProps, {}> {
  private readonly params: Params;
  private readonly myOrderHref: string;
  private readonly myOrdersHref: string;
  private readonly parentWindow: ParentWindow;

  constructor(props: CancelPageProps) {
    super(props);

    this.params = getQueryParams();

    this.parentWindow = new ParentWindow();
    const parentLocation = this.parentWindow.location();
    this.myOrderHref = `${parentLocation.protocol}//${parentLocation.host}/sales/order/view/order_id/${
      this.params["magento_order_id"]
    }`;
    this.myOrdersHref = `${parentLocation.protocol}//${parentLocation.host}/sales/order/history`;

    this.cancelOrder = this.cancelOrder.bind(this);
    this.gotoMyOrder = this.gotoMyOrder.bind(this);
    this.reasonConfirmed = this.reasonConfirmed.bind(this);
    this.termsConfirmed = this.termsConfirmed.bind(this);
  }

  render() {
    let alreadyCancelled = this.props.subOrder.status === SubOrderStatus.Cancelled;
    const alreadyCancelledCopy = (
      <>
        This order has been <span className="c-order-status c-order-status--cancelled">cancelled</span>.
      </>
    );

    return (
      <MyAccountPage
        banner={{
          heading: "Cancel my order",
          subHeading: (
            <>
              Order: <strong>#{this.props.subOrder.sub_order_id}</strong>
            </>
          ),
          content: (
            <>
              {alreadyCancelled ? (
                <span className="js-already-cancelled">{alreadyCancelledCopy}</span>
              ) : (
                <span>
                  No longer require your GlamCorner booking? Please complete the below to cancel your booking and
                  request a refund.
                </span>
              )}
            </>
          )
        }}
        breadcrumbs={[
          { label: "My account", href: null },
          { label: "My orders", href: this.myOrdersHref },
          {
            label: `Order #${this.props.subOrder.sub_order_id}`,
            href: this.myOrderHref
          },
          { label: "Cancel my order", href: null }
        ]}
        header={
          <>
            Cancel My Order
            {alreadyCancelled ? (
              <div className="js-already-cancelled" style={{ marginTop: "10px" }}>
                {alreadyCancelledCopy}
              </div>
            ) : (
              ""
            )}
          </>
        }
        onCloseHeader={this.gotoMyOrder}
      >
        {alreadyCancelled ? (
          <>
            <CancelledOrder subOrder={this.props.subOrder} />
            <div className="js-return-to-my-orders c-cancel-my-order__return-to-my-orders">
              <Button
                text="Return to My Orders"
                className="c-button--secondary"
                onClick={() => {
                  this.parentWindow.goto(this.myOrdersHref);
                }}
              />
            </div>
          </>
        ) : (
          <CancelSteps
            subOrder={this.props.subOrder}
            onCancellationConfirmed={this.cancelOrder}
            onReasonConfirmed={this.reasonConfirmed}
            onTermsConfirmed={this.termsConfirmed}
          />
        )}
      </MyAccountPage>
    );
  }

  private cancelOrder(reason: string) {
    sendAnalytics(CancelMyOrderAnalyticsEvents.CancelMyOrderConfirmedCancellation, {
      subOrderId: this.props.subOrder.sub_order_id
    });

    if (this.props.onSubmit) {
      this.props.onSubmit(reason);
    }
  }

  private gotoMyOrder() {
    this.parentWindow.goto(this.myOrderHref);
  }

  private reasonConfirmed(reason: string) {
    sendAnalytics(CancelMyOrderAnalyticsEvents.CancelMyOrderConfirmedReason, {
      reason,
      subOrderId: this.props.subOrder.sub_order_id
    });
  }

  private termsConfirmed() {
    sendAnalytics(CancelMyOrderAnalyticsEvents.CancelMyOrderConfirmedTerms, {
      subOrderId: this.props.subOrder.sub_order_id
    });
  }
}

export default hot(module)(CancelPage);
