import * as React from "react";
import * as ReactDOM from "react-dom";
import "../../index.scss";
import "../../core/polyfills";
import { CancelPageContainer } from "./CancelPageContainer";

if (window.document.domain.endsWith("glamcorner.com.au")) {
  window.document.domain = "glamcorner.com.au";
}

ReactDOM.render(<CancelPageContainer />, document.getElementById("root"));
