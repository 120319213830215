import * as React from "react";
import { OrderItemViewModel } from "../viewModels/OrderItemViewModel";

interface OrderItemProps {
  orderItem: OrderItemViewModel;
}

export const OrderItem: React.SFC<OrderItemProps> = props => (
  <div className="c-order-item">
    <div className="c-order-item__img">
      <img src={props.orderItem.image_url} width="70" height="105" />
    </div>

    <div className="c-order-item__info">
      <div className="c-order-item__name">{props.orderItem.name}</div>
      <div className="c-order-item__designer">{props.orderItem.designer}</div>
      <div className="c-order-item__size">
        Size <strong>{props.orderItem.size}</strong>
      </div>
    </div>

    <div className="c-order-item__price">
      <span className="c-order-item__price-symbol">$</span>
      <span className="c-order-item__price-value">{props.orderItem.pricePaidInDollars()}</span>
    </div>
  </div>
);
