import * as React from "react";
import { SubOrderViewModel } from "../viewModels/SubOrderViewModel";
import { OrderItems } from "../components/OrderItems";
import { CancelOrderTotals } from "./CancelOrderTotals";

interface CancelledOrderProps {
  subOrder: SubOrderViewModel;
}

export const CancelledOrder: React.SFC<CancelledOrderProps> = props => (
  <section className="c-my-order__panel grid-container l-vertical-space" style={{ maxWidth: "600px" }}>
    <div className="c-my-order__column">
      <div className="l-vertical-space-bottom">
        <OrderItems
          orderItems={props.subOrder.orderItems}
          itemsTotalInDollars={props.subOrder.itemsTotalInDollars()}
          countText={props.subOrder.countText()}
          showItemsHeader={true}
        />
      </div>

      <CancelOrderTotals subOrder={props.subOrder} />
    </div>
  </section>
);
