import * as React from "react";

export interface SelectProps {
  value?: string;
  onChange: (value: string) => void;
}

export const Select: React.SFC<SelectProps> = props => (
  <div className="c-select">
    <select className="c-select__select" value={props.value} onChange={event => props.onChange(event.target.value)}>
      {props.children}
    </select>
  </div>
);
