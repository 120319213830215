import * as React from "react";
import { ReactNode } from "react";

export type ActivatableElement = React.ReactElement<{ active: boolean }>;

export interface StepProps {
  tabName: ReactNode;
  children: ActivatableElement[] | ActivatableElement;
}

export class Step extends React.Component<StepProps, any> {
  render() {
    return "";
  }
}
